<template>
    <div class="home_box">
        <div class="o_info_top">
            <div class="o_info_top_1">
                <el-breadcrumb separator-class="el-icon-arrow-right">
                    <el-breadcrumb-item :to="{ path: '/candidate' }">{{$t('main.text47')}}</el-breadcrumb-item>
                    <el-breadcrumb-item><span class="o_info_top">{{$t('main.text48')}}</span></el-breadcrumb-item>
                </el-breadcrumb>
            </div>
            <div class="o_info_top2_1">
                <div>
                    <div class="o_info_top2_2">{{$t('main.text87')}}</div>
                    <div class="o_info_top2_3">{{top_data.name}}</div>
                </div>
                <div>
                    <div class="o_info_top2_2">{{$t('main.text88')}}</div>
                    <div class="o_info_top2_3">{{top_data.mobile}}</div>
                </div>
                <div>
                    <div class="o_info_top2_2">{{$t('main.text89')}}</div>
                    <div class="o_info_top2_3">{{top_data.email}}</div>
                </div>
                <!-- <div>
                    <div class="o_info_top2_2">岗位</div>
                    <div class="o_info_top2_3">{{top_data.job}}</div>
                </div> -->
                <!-- <div>
                    <div class="o_info_top2_2">状态</div>
                    <div class="o_info_top2_3">
                        <span class="type3" v-if="top_data.status==1">待⾯试</span>
                        <span class="type3" v-else-if="top_data.status==5">已⼊职</span>
                    </div>
                </div> -->
            </div>
        </div>
        <div class="h_table">
            <div class="h_table_top"> 
                <div class="t_top_1">{{$t('main.text48')}}</div>
            </div>
            <el-table
            :data="tableData"
            :cell-style="{textAlign:'center',color:'#333'}"
            :header-cell-style="{textAlign:'center',background:'#ECF3FD',color:'#838383'}"
            style="width: 100%">
                <el-table-column :label="$t('main.text70')"
                    type="index"
                    width="50">
                </el-table-column>
                <el-table-column :label="$t('main.text90')">
                    <span slot-scope="props">
                        {{ props.row.order_id }}
                    </span>
                </el-table-column>
                <el-table-column :label="$t('main.text91')">
                    <span slot-scope="props">
                        {{ time_format(props.row.c_time) }}
                    </span>
                </el-table-column>
                <el-table-column :label="$t('main.text92')">
                    <span slot-scope="props">
                        <span v-if="props.row.schedule_status==1"><span class="dot1"></span>{{$t('main.text55')}}</span>
                        <span v-else-if="props.row.schedule_status==2"><span class="dot1 dot_bgc2"></span>{{$t('main.text75')}}</span>
                        <span v-else-if="props.row.schedule_status==5"><span class="dot1 dot_bgc2"></span>{{$t('main.text57')}}</span>
                        <span v-else-if="props.row.schedule_status==6"><span class="dot1 dot_bgc2"></span>{{$t('main.text57')}}</span>
                        <span v-else-if="props.row.schedule_status==7"><span class="dot1 dot_bgc2"></span>{{$t('main.text58')}}</span>
                        <span v-else-if="props.row.schedule_status==8"><span class="dot1 dot_bgc2"></span>{{$t('main.text59')}}</span>
                        <span v-else-if="props.row.schedule_status==10"><span class="dot1 dot_bgc2"></span>{{$t('main.text60')}}</span>
                        <span v-else-if="props.row.schedule_status==11"><span class="dot1 dot_bgc2"></span>{{$t('main.text61')}}</span>
                        <span v-else-if="props.row.schedule_status==15"><span class="dot1 dot_bgc2"></span>{{$t('main.text57')}}</span>
                        <span v-else-if="props.row.schedule_status==20"><span class="dot1 dot_bgc2"></span>{{$t('main.text62')}}</span>
                        <span v-else-if="props.row.schedule_status==25"><span class="dot1 dot_bgc2"></span>{{$t('main.text59')}}</span>
                        <span v-else-if="props.row.schedule_status==26"><span class="dot1 dot_bgc2"></span>{{$t('main.text62')}}</span>
                        <span v-else-if="props.row.schedule_status==30"><span class="dot1 dot_bgc2"></span>{{$t('main.text58')}}</span>
                        <span v-else-if="props.row.schedule_status==31"><span class="dot1 dot_bgc2"></span>{{$t('main.text58')}}</span>
                        <span v-else-if="props.row.schedule_status==32"><span class="dot1 dot_bgc2"></span>{{$t('main.text62')}}</span>
                        <span v-else-if="props.row.schedule_status==33"><span class="dot1 dot_bgc2"></span>{{$t('main.text63')}}</span>
                        <span v-else-if="props.row.schedule_status==35"><span class="dot1 dot_bgc2"></span>{{$t('main.text64')}}</span>
                        <span v-else-if="props.row.schedule_status==36"><span class="dot1 dot_bgc2"></span>{{$t('main.text65')}}</span>
                        <span v-else-if="props.row.schedule_status==37"><span class="dot1 dot_bgc2"></span>{{$t('main.text59')}}</span>
                        <span v-else-if="props.row.schedule_status==40"><span class="dot1 dot_bgc2"></span>{{$t('main.text66')}}</span>
                        <span v-else-if="props.row.schedule_status==45"><span class="dot1 dot_bgc2"></span>{{$t('main.text67')}}</span>
                        <span v-else-if="props.row.schedule_status==50"><span class="dot1 dot_bgc2"></span>{{$t('main.text68')}}</span>
                        <span v-else>-</span>
                    </span>
                </el-table-column>
                <el-table-column :label="$t('main.text93')">
                    <span slot-scope="props">
                        <span class="details" @click="to_candidate_info_history(props.row)">{{$t('main.text94')}}</span>
                    </span>
                </el-table-column>

            </el-table>

            <el-pagination
                class="pagination_box"
                v-if="total"
                background
                @size-change="size_change"
                @current-change="current_change"
                :current-page="currentPage"
                :page-sizes="[10, 20, 30, 40]"
                :page-size="limit"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total">
            </el-pagination>

        </div>

        <!-- 继续 弹框 -->
        <el-dialog
            title=" "
            :visible.sync="continue_dlg"
            width="500px"
            top="20%"
            center>
            <div class="dlg_body">{{$t('main.text95')}}</div>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="continue_dlg = false" class="confirm_btn">{{$t('main.text4')}}</el-button>
                <el-button @click="continue_dlg = false" class="cancel_btn">{{$t('main.text5')}}</el-button>
            </span>
        </el-dialog>

        <!-- 终止 弹框 -->
        <el-dialog
            title=" "
            :visible.sync="termination_dlg"
            width="500px"
            top="20%"
            center>
            <div class="dlg_body">{{$t('main.text96')}}</div>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="termination_dlg = false" class="confirm_btn">{{$t('main.text4')}}</el-button>
                <el-button @click="termination_dlg = false" class="cancel_btn">{{$t('main.text5')}}</el-button>
            </span>
        </el-dialog>

        <!-- 查看详情 弹框 -->
        <el-dialog
            :title="$t('main.text287')"
            :visible.sync="o_info_dlg"
            width="500px"
            top="20%"
            center>
            <div class="dlg_body">XXX</div>
            <span slot="footer" class="dialog-footer">
                &nbsp;
            </span>
        </el-dialog>

        <!-- 修改时间 添加时间 弹框 -->
        <el-dialog
            :title="$t('main.text97')"
            :visible.sync="set_time_dlg"
            width="500px"
            top="20%"
            center>
            <div class="dlg_body">
                <div class="time_box">
                    <el-date-picker
                        @change="change_time"
                        v-model="time"
                        type="datetime"
                        value-format="yyyy-MM-dd HH:mm:ss"
                        :placeholder="$t('main.text97')">
                    </el-date-picker>
                </div>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="set_time_dlg = false" class="confirm_btn">{{$t('main.text4')}}</el-button>
                <el-button @click="set_time_dlg = false" class="cancel_btn">{{$t('main.text5')}}</el-button>
            </span>
        </el-dialog>

    </div>
</template>
    
<script>
import {candidates_get} from '@/api/api';
export default{
    data() {
        return {
            currentPage: 1, //选中的页数
            page: 1, //分页 从 1 开始
            limit: 10, //每页条数
            total: 0, //一共多少条
            tableData: [],
            continue_dlg:false,
            termination_dlg:false,
            o_info_dlg:false,
            set_time_dlg:false,
            time:'',

            userid:this.$route.query.userid||'',
            top_data:{},
            api_userid:"",

        }
    },
    mounted() {
        this.get_list();
    },
    methods: {
        current_change(v) {
            this.page = v;
            this.limit = 10;
            this.get_list();
        },
        size_change(v) {
            this.limit = v;
            this.get_list();
        },
        change_time(v){
            console.log(v);
        },
        async get_list(){
            const res = await candidates_get({
                userid:this.userid
            });
            if(res.data.code!=200){
                return this.msg("error",res.data.msg);
            }
            this.top_data = res.data.data;
            this.tableData = res.data.data.list;
            this.total = res.data.data.countAll;
            this.api_userid = res.data.data.userid;
        },
        to_candidate_info_history(row){
            this.$router.push({
                name:'candidate_info_history',
                query:{
                    h_order_id:row.order_id,
                    h_userid:this.api_userid,
                    form_type:"candidate",//候选人进入
                }
            })
        }

    },

}
</script>
    
<style scoped>
.h_table{
    border-radius: 6px;
    background: #FFF;
    box-sizing: border-box;
    padding: 20px;
}

.h_table_top{
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #333;
    font-size: 16px;
    margin-bottom: 12px;
}
.t_top_Right>div{
    display: inline-block;
}
.t_top_2{
    max-width: 160px;
    margin-right: 20px;
}
.t_top_2 >>> .el-input__inner,
.t_top_3 >>> .el-input__inner{
    border-color: #A9A9A9;
    color: #BCBCBC;
}
.t_top_3{
    max-width: 200px;
    margin-right: 30px;
    position: relative;
}
.t_top_3 i{
    position: absolute;
    left: 15px;
    top: 50%;
    transform: translateY(-50%);
    color: #BCBCBC;
    font-size: 20px;
}
.t_top_3 >>> .el-input__inner{
    text-indent: 2em;
}
.t_top_3 >>> .el-input.is-active .el-input__inner,
.t_top_3 >>> .el-input__inner:focus {
    border-color: #409EFF;
}
/* ---- */
.o_info_top_1{
    margin-bottom: 30px;
}
.o_info_top2_1{
    display: flex;
    justify-content: space-around;
    box-sizing: border-box;
    padding: 25px 20px;
    text-align: center;
    border-radius: 6px;
    background: #FFF;
    margin-bottom: 30px;
}
.o_info_top2_2{
    color: #A9A9A9;
    font-size: 16px;
    margin-bottom: 20px;
}
.o_info_top2_3{
    color: #333;
    font-size: 20px;
}
</style>
